<template>
  <div
    v-scroll="handleScrollPosition"
  >
    <div class="d-flex align-center">
      <Controls
        ref="controls"
        :filters="value.requestParams.filters"
        @input="handleControls($event)"
        @list="$emit('file', $event)"
        @download="handleDownload"
        @create-url="handleCreateUrl"
        @save="saveCompilation(fileType)"
        :disabled="!canSaveCompilation"
        :downloading="isDownloading"
        :show-tooltip="showTooltip"
        :client-id="value.client_id"
        :client-advert-id="value.id"
      />
    </div>
    <Table
      :options="value.requestParams.options"
      @input="localRequestParams.options = $event"
      :loading="loadingTable"
      :items="items"
      :items-length="itemsLength"
      :client_id.sync="value.client_id"
      :client_advert_id="value.id"
      :selected.sync="localState.selectedState"
      :opened.sync="localState.openedItems"
      @detail="$emit('detail', $event)"
    />
  </div>
</template>

<script>
import Table from './File/Table'
import Controls from './File/Controls'
import list from '@/components/mixins/list'
import { processGetData } from '@/components/utils/common'
import Qs from 'qs'
import { file, clientUrl } from '@/config'

const maxIds = 50

export default {
  mixins: [list],
  name: 'File',
  components: { Controls, Table },
  data: function () {
    return {
      fillTableUrl: '/advert',
      downloading: false,
      fileName: null,
      showTooltip: false,
      isSavingData: false
    }
  },
  computed: {
    isDownloading () {
      let isDownloading = this.downloading
      if (this.fileName) {
        isDownloading = !this.$store.getters['download/getFileByName'](this.fileName).ready
      }
      return isDownloading
    },
    fileType () {
      return file.value
    }
  },
  watch: {
    async isDownloading () {
      if (this.fileName && this.$store.getters['download/getFileByName'](this.fileName).ready) {
        await this.downloadFile()
      }
    }
  },
  methods: {
    async handleDownload () {
      this.downloading = true
      this.fileName = await this.$store.dispatch('download/download', {
        payload: {
          url: 'excel',
          data: processGetData({
            options: this.value.requestParams.options,
            filters: this.handleFilters()
          })
        }
      })
      return true
    },
    async downloadFile () {
      const response = await this.axios({
        url: '/files/' + this.fileName,
        method: 'GET',
        responseType: 'blob'
      })
      if (response) {
        let fileURL = window.URL.createObjectURL(new Blob([response.data]))
        let fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', this.fileName.split('/')[2])
        document.body.appendChild(fileLink)

        fileLink.click()
      }
    },
    handleCreateUrl () {
      if (this.localState.selectedState.selectedItems.length > maxIds) {
        this.$store.dispatch('systemMessages/error', 'Для формирования ссылки количество выбранных ID не должно превышать ' + maxIds)
      } else if (!this.localState.selectedState.selectedItems.length && !this.localState.selectedState.selectAll && !this.$refs.controls.isFilterActive()) {
        this.$store.dispatch('systemMessages/error', 'Для формирования ссылки необходимо выбрать объявление или установить фильтр')
      } else {
        // Массив атрибутов, которые нужно удалить из sortBy
        const attributesToRemove = ['viewed', 'approved', 'offered']
        // Новый массив sortBy без строк из attributesToRemove
        const newSortBy = []
        const newSortDesc = []

// Проходим по исходному массиву sortBy и проверяем каждый элемент
        this.value.requestParams.options.sortBy.forEach((item, index) => {
          // Проверяем, содержится ли элемент в attributesToRemove
          if (!attributesToRemove.includes(item)) {
            // Если элемент не содержится в attributesToRemove, добавляем его в новый массив
            newSortBy.push(item)
            // Добавляем соответствующий элемент из sortDesc в новый массив
            newSortDesc.push(this.value.requestParams.options.sortDesc[index])
          }
        })

        const url = clientUrl + '/sale?' + Qs.stringify(
          processGetData({
            // игнорируем номер страницы с которой была создана выборка
            // и сортировку
            options: {
              ...this.value.requestParams.options,
              page: 1,
              sortBy: newSortBy,
              sortDesc: newSortDesc
            },
            filters: this.handleFilters()
          }),
          {
            arrayFormat: 'brackets',
            encode: false
          }
        )
        this.$copyText(url)
        this.showTooltip = true
        setTimeout(() => {
          this.showTooltip = false
        }, 1000)
      }
    },
    handleFilters () {
      let filters
      if (!this.localState.selectedState.selectedItems.length) {
        filters = { ...this.value.requestParams.filters, excludeIds: this.localState.selectedState.excludedItems }
      } else {
        filters = { selectedIds: this.localState.selectedState.selectedItems }
      }
      return filters
    }
  }
}
</script>

<style scoped>

</style>
