export default [
  {
    text: ' ',
    align: 'left',
    sortable: false,
    value: '',
    width: '10px'
  },
  {
    text: '#',
    align: 'left',
    sortable: false,
    value: 'index',
    width: '10px'
  },
  {
    text: 'Опубл.',
    align: 'left',
    sortable: true,
    value: 'is_published',
    width: '45px'
  },
  {
    text: 'Снять',
    align: 'left',
    sortable: true,
    value: 'viewed',
    width: '15px'
  },
  {
    text: 'Ссылка',
    align: 'left',
    sortable: true,
    value: 'approved',
    width: '15px'
  },
  {
    text: 'Показ',
    align: 'left',
    sortable: true,
    value: 'offered',
    width: '15px'
  },
  {
    text: 'Эксклюз.',
    align: 'left',
    sortable: true,
    value: 'exclusive_status',
    width: '60px'
  },
  {
    text: 'Создано',
    align: 'left',
    sortable: true,
    value: 'created_at',
    width: '80px'
  },
  {
    text: 'Касание',
    align: 'left',
    sortable: true,
    value: 'updated_at',
    width: '80px'
  },
  {
    text: 'Кво к-т',
    align: 'center',
    sortable: true,
    value: 'room_count',
    width: '25px'
  },
  {
    text: 'Планир',
    align: 'left',
    sortable: true,
    value: 'kitchen_type',
    width: '50px'
  },
  {
    text: 'Район',
    align: 'left',
    sortable: true,
    value: 'districtCustom',
    width: '100px'
  },
  {
    text: 'Подрайон',
    align: 'left',
    sortable: true,
    value: 'subdistrict',
    width: '100px'
  },
  {
    text: 'Адрес',
    align: 'left',
    sortable: true,
    value: 'address',
    width: '130px'
  },
  {
    text: 'Э-ж',
    align: 'left',
    sortable: true,
    value: 'floor',
    width: '30px'
  },
  {
    text: 'Э-ть',
    align: 'left',
    sortable: true,
    value: 'total_floors',
    width: '30px'
  },
  {
    text: 'Мат. стен',
    align: 'left',
    sortable: true,
    value: 'buildingMaterial',
    width: '40px'
  },
  {
    text: 'Площадь',
    align: 'left',
    sortable: true,
    value: 'area_common',
    width: '50px'
  },
  {
    text: 'Жил',
    align: 'left',
    sortable: true,
    value: 'area_living',
    width: '40px'
  },
  {
    text: 'Кух',
    align: 'left',
    sortable: true,
    value: 'area_kitchen',
    width: '40px'
  },
  {
    text: 'Состояние',
    align: 'left',
    sortable: true,
    value: 'flat_status',
    width: '70px'
  },
  {
    text: 'Цена',
    align: 'left',
    sortable: true,
    value: 'price',
    width: '40px'
  },
  {
    text: 'Телефон',
    align: 'left',
    sortable: true,
    value: 'phone',
    width: '100px'
  },
  {
    text: 'Фото',
    align: 'left',
    sortable: true,
    value: 'photoCount',
    width: '30px'
  },
  {
    text: 'Дом',
    align: 'left',
    sortable: true,
    value: 'isReady',
    width: '40px'
  },
  {
    text: 'ID',
    align: 'left',
    sortable: true,
    value: 'id',
    width: '50px'
  }
]
