<template>
  <v-container
    fluid
    class="pt-3 pb-0"
  >
    <v-row class="text-left">
      <v-col
        cols="6"
        class="text-left pt-0 pb-0 d-flex align-center"
      >
        <v-menu
          offset-y
          left
          :close-on-content-click="true"
          offset-x
        >
          <template v-slot:activator="{ on }">
            <v-btn
              class="primary mr-3"
              :loading="downloading"
              v-on="on"
              small
            >
              <v-icon
                small
                class="mr-2"
              >
                mdi-chevron-down
              </v-icon>
              Сохранить как ...
            </v-btn>
          </template>
          <v-card>
            <v-list
              nav
              dense
            >
              <v-list-item
                @click="$emit('download')"
              >
                <v-list-item-icon>
                  <v-icon> mdi-download</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Файл</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                @click="$emit('create-url')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-link-variant</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Ссылка</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                :disabled="disabled"
                @click="$emit('save')"
              >
                <v-list-item-icon>
                  <v-icon>mdi-content-save</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Вкладка</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
        <AdvertDialogFilter
          :value="localFilters"
          @input="handleInputFilter($event)"
          :loading="loading"
        />
        <Autocomplete
          :value="localFilters.phoneFilter"
          @input="handlePhoneFilter($event)"
          item-value="id"
          item-text="phone"
          placeholder="поиск по номеру телефона"
          prepend-icon="mdi-database-search"
          mask="8 ### ### ## ##"
          url="/phone/autocomplete"
          class="pl-5 flex-grow-2"
        />
        <v-text-field
          :value="localFilters.advertId"
          @input="localId = $event"
          @keyup.enter="handleIdFilter(localId)"
          placeholder="поиск по ID"
          prepend-icon="mdi-nfc-search-variant "
          class="pl-5"
          style="min-width: 88px;"
        />
        <v-tooltip
          :value="showTooltip"
          top
        >
          <span>Скопировано</span>
        </v-tooltip>
      </v-col>
      <v-col
        cols="4"
        class="text-left pt-0 pb-0"
      >
        <v-select
          :value="advertType"
          @input="handleIsVerifiedFilter($event)"
          :items="advertTypeItems"
          label="выберите тип объявления"
          prepend-icon="mdi-playlist-check"
          elevation="1"
          class="text-left"
          item-value="value"
          item-text="text"
          return-object
        ></v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AdvertDialogFilter from '@/components/views/admin/advert/Common/Controls/AdvertDialogFilter'
import Autocomplete from '@/components/views/admin/common/Autocomplete'
import { advertTypeItems } from '@/config'
import advertControls from '@/components/mixins/controls/advertControls'

export default {
  mixins: [advertControls],
  name: 'Controls',
  components: { AdvertDialogFilter, Autocomplete },
  props: {
    filters: Object,
    downloading: Boolean,
    showTooltip: Boolean,
    disabled: Boolean,
    clientId: Number,
    clientAdvertId: Number
  },
  data: function () {
    return {
      advertTypeItems: advertTypeItems,
      localId: null
    }
  },
  computed: {
    advertType () {
      const value = this.advertTypeItems.find(option => option.value.isVerified === this.filters.isVerified && option.value.advStatus === this.filters.advStatus)
      return value ? value.value : this.advertTypeItems[0].value
    }
  }
}
</script>

<style scoped>

</style>
